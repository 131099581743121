// Color system
$white: #ffffff !default;
$gray-100: #fcfaf6 !default;
$gray-200: #f9f5eb !default;
$gray-300: #ede9de !default;
$gray-400: #bcb6a3 !default;
$gray-500: #908c7d !default;
$gray-600: #7b7768 !default;
$gray-700: #666151 !default;
$gray-800: #555145 !default;
$gray-900: #484439 !default;
$black: #000000 !default;

$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #ff6200 !default;
$burnt-orange: #e55902 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;

$brand: $orange !default;
$brand-darker: $burnt-orange !default;
$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$lighter: $gray-100 !default;
$light: $gray-200 !default;
$lightish: $gray-300 !default;
$mid: $gray-400 !default;
$dark: $gray-800 !default;
$darker: $gray-900 !default;

$very-unhappy: #f83f14 !default;
$unhappy: #f88200 !default;
$neutral: #f8d800 !default;
$happy: #80e80f !default;
$very-happy: #64c100 !default;
